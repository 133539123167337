import AvatarList from '@/common/components/AvatarList';
import { ApprovalStateIdPrefix } from '@/common/graphql/scalars';
import WorkflowStatusBadge from '@/modules/workflows/components/WorkflowStatusBadge/WorkflowStatusBadge';
import { isNullish } from '@/utils/common/isNullish';
import { formatDateToMDHHmm_or_YYYYMDHHmm } from '@/utils/date/date';
import { assertNever } from '@/utils/types/types';
import { gql } from '@apollo/client';
import { Flex, HStack, LinkBox, LinkOverlay, Stack, Text } from '@chakra-ui/react';
import { Link } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import type { WorkflowCard_WorkflowExecutionFragment } from './WorkflowCard.generated';

gql`
fragment WorkflowCard_WorkflowExecution on WorkflowExecution {
    id
    currentStateId
    nextApproverUsers {
      id
      name
    }
    lastSubmittedBy {
      id
      name
    }
    lastSubmittedAt
    lastEvent {
      __typename
      id
      type
      createdAt
      ... on WorkflowSubmittedEvent {
        submittedBy {
          id
          name
        }
      }
      ... on WorkflowCancelledEvent {
        cancelledBy {
          id
          name
        }
      }
      ... on WorkflowRejectedEvent {
        rejectedBy {
          id
          name
        }
      }
      ... on WorkflowApprovedEvent {
        approvedBy {
          id
          name
        }
      }
      ... on WorkflowResubmittedEvent {
        resubmittedBy {
          id
          name
        }
      }
    }
    report {
      id
      latestVersion {
        id
        title
      }
      reportTemplateVersion {
        id
        name
      }
    }
}
`;

type Props = {
  execution: WorkflowCard_WorkflowExecutionFragment;
  isCurrentItem?: boolean;
};

export default function WorkflowCard({ execution, isCurrentItem }: Props) {
  return (
    <LinkBox
      py={2}
      px={3}
      key={execution.id}
      bg={isCurrentItem ? 'primary.50' : 'transparent'}
      _hover={{
        bg: 'neutral.50',
      }}
    >
      <Stack align='stretch' textAlign='left' gap={1}>
        <Text color='text.secondary' fontSize='xs'>
          {execution.report?.reportTemplateVersion?.name}
        </Text>
        <Flex alignItems='center' gap={2}>
          <LinkOverlay
            as={Link}
            to={`/workflows/${execution.id}`}
            fontSize='lg'
            color='text.primary'
            fontWeight='semibold'
          >
            {execution.report?.latestVersion?.title}
          </LinkOverlay>
          <WorkflowStatusBadge stateId={execution.currentStateId} />
        </Flex>
        <CurrentStatusMessage execution={execution} />

        <Text color='text.secondary' fontSize='xs'>
          {`${formatDateToMDHHmm_or_YYYYMDHHmm(execution.lastSubmittedAt)} ${execution.lastSubmittedBy?.name}が申請`}
        </Text>
      </Stack>
    </LinkBox>
  );
}

const CurrentStatusMessage = ({
  execution,
}: { execution: WorkflowCard_WorkflowExecutionFragment }) => {
  const { t } = useTranslation('workflows');
  if (isNullish(execution.lastEvent)) {
    return null;
  }

  switch (execution.currentStateId) {
    case 'Created':
      // ありえない
      return null;
    case 'Rejected':
      // 最後のeventがrejectedなはず
      return execution.lastEvent.__typename === 'WorkflowRejectedEvent' ? (
        <HStack gap={1}>
          <AvatarList users={[execution.lastEvent.rejectedBy]} iconThreshold={1} />
          <Text textColor='text.primary' fontSize='sm'>
            {t('status-message.rejected', {
              userName: execution.lastEvent.rejectedBy.name,
            })}
          </Text>
        </HStack>
      ) : null;
    case 'Cancelled':
      return execution.lastEvent.__typename === 'WorkflowCancelledEvent' ? (
        <HStack gap={1}>
          <AvatarList users={[execution.lastEvent.cancelledBy]} iconThreshold={1} />
          <Text textColor='text.primary' fontSize='sm'>
            {t('status-message.cancelled', {
              userName: execution.lastEvent.cancelledBy.name,
            })}
          </Text>
        </HStack>
      ) : null;
    case 'Approved':
      return execution.lastEvent.__typename === 'WorkflowApprovedEvent' ? (
        <HStack gap={1}>
          <AvatarList users={[execution.lastEvent.approvedBy]} iconThreshold={1} />
          <Text textColor='text.primary' fontSize='sm'>
            {t('status-message.approved', {
              userName: execution.lastEvent.approvedBy.name,
            })}
          </Text>
        </HStack>
      ) : null;
    default:
      if (execution.currentStateId.startsWith(ApprovalStateIdPrefix)) {
        return (
          <HStack gap={1}>
            <AvatarList users={execution.nextApproverUsers} iconThreshold={3} />
            <Text textColor='text.primary' fontSize='sm'>
              {t('status-message.pending', {
                userName: execution.nextApproverUsers.map((u) => u.name).join(', '),
              })}
            </Text>
          </HStack>
        );
      } else {
        assertNever(execution.currentStateId);
      }
  }
};
